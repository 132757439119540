import { createStore } from 'vuex'

export default createStore({
  state: {
    currentSong: 0,
    currentName: 'Grondwetbank',
    playing: false,
    playlist: [
      { 
        number: 1,
        title: "Grondwetbank",
        subtitle: "Zitten op de tekst van artikel 1 uit onze Grondwet",
        artist: "",
        url: require('/static/audio/1.mp3'),
        image: require('../assets/images/buildings/grondwetbank.jpg') },
      { 
        number: 2,
        title: "Hofvijver",
        subtitle: "Hier vond zo’n 50 jaar geleden de eerste LHBTI-demonstratie van Europa plaats",
        artist: "",
        url: require('/static/audio/2.mp3'),
        image: require('../assets/images/buildings/hofvijver.jpg') },
      { 
        number: 3,
        title: "Paleis Noordeinde",
        subtitle: "Twee donkere jongetjes als cadeau?",
        artist: "",
        url: require('/static/audio/3.mp3'),
        image: require('../assets/images/buildings/paleis.jpg') },
      { 
        number: 4,
        title: "Vredespaleis",
        subtitle: "Dit paleis is niet voor de koning, maar voor ons allemaal",
        artist: "",
        url: require('/static/audio/4.mp3'),
        image: require('../assets/images/buildings/vredespaleis.jpg') },
      { 
        number: 5,
        title: "Long Walk To Freedom",
        subtitle: "Een stukje van Nelson Mandela's wandeling in Den Haag",
        artist: "",
        url: require('/static/audio/5.mp3'),
        image: require('../assets/images/buildings/longwalkoffreedom.jpg') },
      { 
        number: 6,
        title: "Hofje van Nieuwkoop",
        subtitle: "Hier was ooit een gratis veilige plek voor vrouwen",
        artist: "",
        url: require('/static/audio/6.mp3'),
        image: require('../assets/images/buildings/hofjevannieuwkoop.jpg') },
      { 
        number: 7,
        title: "Chinese buurt",
        subtitle: "Ontdek hier wat Joden en Chinezen met elkaar gemeen hebben",
        artist: "",
        url: require('/static/audio/7.mp3'),
        image: require('../assets/images/buildings/chinesebuurt.jpg') },
      { 
        number: 8,
        title: "Nieuwspoort",
        subtitle: "Hier vond een gevecht tussen discriminatie en de vrijheid van meningsuiting plaats",
        url: require('/static/audio/8.mp3'),
        image: require('../assets/images/buildings/nieuwspoort.jpg') }
    ]    
  },
  mutations: {
    setCurrentSong (state, data) {
      state.currentSong = data
      state.currentName = state.playlist[data].title
    },
    setCurrentName (state, data) {
      state.currentSong = data
    },  
    setPlaying (state, data) {
      state.playing = data
    }  
  },
  getters: {
    currentName: state => {
      return state.currentName;
    }
  },
  actions: {
  },
  modules: {
  }
})
