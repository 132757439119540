<template>
  <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 476.9 691.9" style="enable-background:new 0 0 476.9 691.9;" xml:space="preserve">
  <g>
    <rect x="232.4" y="434" width="12" height="257.9"/>
    <path d="M244.4,338.3h-6h-75.4v12h69.4v55.1c-14.9,2.8-26.3,16-26.3,31.7v117.5h12V437.1c0-11.2,9.1-20.3,20.3-20.3
      s20.3,9.1,20.3,20.3v117.5h12V437.1c0-15.8-11.3-28.9-26.3-31.7v-55.1h69.4v-12H244.4z"/>
    <rect x="188" y="627.3" width="24.2" height="12"/>
    <rect x="264.8" y="578.8" width="24.2" height="12"/>
  </g>
  <g>
    <g>
      <path d="M30.5,339.5L0,166.5L355.5,53.4l39.1,221.8l-213.3,37.6l-10.8,9.7l-13.4-5.4L30.5,339.5z M13.6,174.8l26.6,150.8
        l118.1-20.8l9.7,3.9l7.8-7l204.9-36.1L346.1,69L13.6,174.8z"/>
    </g>
    <g>
      <g>
        <path class="st0" d="M30.7,146.5L18.2,87l13.3-4.3l7.2,43.9l7.1-48.5l13.6-4.5l-12.2,68.1L30.7,146.5z"/>
      </g>
      <g>
        <path class="st0" d="M89.6,129l-7.3-19.5l-3.4,1.6l0,21.2l-13.4,4l-0.1-64.7L81,66.3c10.9-3.7,18.1-0.1,20,9.7
          c1.8,9.4-0.2,20-7,26.2l9.3,22.6L89.6,129z M82.3,97.6c5.6-2.3,6.4-9.2,5.4-14.6c-0.8-4.3-3.2-5.2-6.9-4l-2,0.7l0,19.4L82.3,97.6
          z"/>
      </g>
      <g>
        <path class="st0" d="M109,123.1l-0.1-66.2l29-10l0,12.9l-15.5,5.3l0,14l11.6-3.8l0,12.8l-11.6,3.8l0,14.4l15.5-4.9l0,12.9
          L109,123.1z"/>
      </g>
      <g>
        <path class="st0" d="M145.7,111.7l-0.1-67.6l13.3-4.7c13.7-4.9,21.6,0.7,24.1,14.9c3.3,18.3,0.3,45.4-18.5,51.4L145.7,111.7z
           M161.6,93.6c8.6-2.8,9.9-23.2,7.7-34.8c-1.1-6.2-3.5-8.7-8.5-6.9l-1.7,0.6l0.1,41.9L161.6,93.6z"/>
      </g>
      <g>
        <path class="st0" d="M190.8,97.2l-0.1-69.2l29-10.5l0,13.5l-15.5,5.6l0,14.6l11.6-4.1l0,13.3l-11.6,4l0,15.1l15.5-5.2l0,13.5
          L190.8,97.2z"/>
      </g>
      <g>
        <path class="st0" d="M273.3,70.8c-1-3-2-5.3-3.2-8.1c-2.9,7.2-7.3,12.2-13.4,14.2c-8,2.7-14.6-1-16.1-9.8
          c-1.7-9.8,3.3-21.6,11.3-28.1c-4.6-3.7-7.2-7.3-8.1-12.7c-1.9-10.5,4.5-21,14.8-24.9c8.9-3.4,15.5-0.8,16.9,7.5
          c1.4,8-3.3,17.9-9.4,23.3c1.7,1.5,3.1,3.2,5.2,5.8l3.2-12.8l13.2-4.9l-9.6,27.5c2.5,4.5,4.9,9.5,7,15.9L273.3,70.8z M257.3,43.7
          c-3.9,6.3-4.7,11.7-4,15.6c0.6,3.3,2.7,4.7,5,3.9c3-1,4.8-4.3,6.7-10.5C262.5,48.9,260.1,46.3,257.3,43.7z M263.4,16.3
          c-0.4-2.3-1.5-3.6-3.4-2.9c-2.6,1-4,4.6-3.2,8.9c0.5,2.7,1.9,4.7,3.3,6C262.9,24.1,264.1,20.2,263.4,16.3z"/>
      </g>
      <g>
        <path d="M63.4,233l-7.3-19.7l-3.4,1.4l0,20.9l-13.4,3.2l-0.1-63.8l15.6-4.3c10.9-3,18.1,0.9,20,10.7c1.8,9.4-0.2,19.7-7,25.4
          l9.3,22.8L63.4,233z M56.1,201.6c5.6-2,6.4-8.7,5.4-14.1c-0.8-4.3-3.2-5.3-6.9-4.3l-2,0.6l0,19.1L56.1,201.6z"/>
      </g>
      <g>
        <path d="M82.8,228.2l-0.1-65.3l29-8.3l0,12.7l-15.5,4.4l0,13.8l11.6-3.2l0,12.6l-11.6,3.1l0,14.2l15.5-4l0,12.7L82.8,228.2z"/>
      </g>
      <g>
        <path d="M151,208c-3.2,3.8-7.1,6.2-12,7.5c-11.6,3-18.8-3.2-21.4-17c-3.5-18.5,0.5-47.2,18.8-52.6c5.7-1.7,10.6-0.8,14.3,2
          l-3.2,13.4c-2.5-2.3-4.9-3.8-8.2-2.8c-8.7,2.5-10.3,24.1-8.1,35.6c1.1,6.1,3.1,9.2,8.5,7.7c2.8-0.8,5.6-3,7.8-6.4L151,208z"/>
      </g>
      <g>
        <path d="M181.4,202.7l0-27.8l-9.8,2.8l0.1,27.6l-13.4,3.6l-0.1-68l13.4-4l0,27.3l9.7-2.8l0-27.4l13.4-4.1l0.1,69.3L181.4,202.7z"
          />
      </g>
      <g>
        <path d="M211.6,194.5l-0.1-56.5l-11.2,3.4l0-13.3l35.8-11.2l0,13.6l-11.2,3.5l0.1,56.9L211.6,194.5z"/>
      </g>
      <g>
        <path d="M253.6,160.6c-3.3-19.2,0.1-51.1,18.5-57c10.9-3.6,18,2,20.6,17.4c3.3,19.6-0.2,52.1-18.5,57.4
          C263.4,181.5,256.2,175.8,253.6,160.6z M278.8,123.8c-0.9-5.3-2.5-7.2-5.3-6.3c-7.1,2.3-7.8,29.4-5.9,40.6
          c0.9,5.3,2.5,7.2,5.3,6.4C280,162.3,280.7,135,278.8,123.8z"/>
      </g>
      <g>
        <path d="M301,168.9l-0.1-73.1l14-4.7c12.1-4.1,19.4,1.4,21.4,13.4c2.6,15.6-1.8,32.6-16.9,37.3l-5.1,1.6l0.1,21.6L301,168.9z
           M316.3,129.1c6.7-2.1,7.7-11.6,6.5-18.9c-1-5.7-3.8-6.3-7.6-5.1l-1,0.3l0,24.3L316.3,129.1z"/>
      </g>
      <g>
        <path d="M64.1,245.5l13.4-3.2l0.1,47.2c0,12.8-4.7,20.4-15.1,22.4c-5.1,1-8.8,0.1-12.1-1.8l2-13c2.2,2,4.7,3.1,7.4,2.6
          c3.4-0.7,4.5-3.2,4.6-7.9L64.1,245.5z"/>
      </g>
      <g>
        <path d="M86.7,305.6l-0.1-65.5l29-7.1l0,12.8l-15.5,3.7l0,13.8l11.6-2.7l0,12.6l-11.6,2.6l0,14.2l15.5-3.4l0,12.8L86.7,305.6z"/>
      </g>
      <g>
        <path d="M139,294.5l-0.1-67.3l29-7.4l0,13.1l-15.5,3.9l0,15.8l12.1-2.9l0,13l-12,2.8l0.2,26L139,294.5z"/>
      </g>
      <g>
        <path d="M174.8,286.6l-0.1-68.6l13.4-3.5l0.1,69.1L174.8,286.6z"/>
      </g>
      <g>
        <path d="M197.3,281.5l-0.1-69.4l29-7.8l0,13.5l-15.5,4.1l0,14.6l11.6-3l0,13.4l-11.6,2.9l0,15.1l15.5-3.7l0,13.5L197.3,281.5z"/>
      </g>
      <g>
        <path d="M241.9,271l-0.1-57.4l-11.2,3l0-13.6l35.8-9.9l0,13.8l-11.2,3.1l0.1,57.8L241.9,271z"/>
      </g>
      <g>
        <path d="M273.5,244.3c3.2,3,7.5,4.7,10.8,3.8c3.9-1,5.8-4.6,5.1-9c-0.7-4.2-3.2-6.7-7.7-9.7c-5.5-3.5-9.7-7.7-11.1-15.9
          c-2.1-12.3,4-24.3,15.4-27.6c5.9-1.7,12.2-0.2,16,2.6l-2.8,14.7c-3.4-3.1-7.1-4.9-10.6-3.9c-3.7,1-5.4,4.9-4.7,8.8
          c0.6,3.6,2.6,5.7,7.4,8.7c6.7,4.2,10.3,10,11.5,17.2c2.2,13-3.9,24.5-15.3,27.4c-6.3,1.6-12.3,0.2-16.7-3.1L273.5,244.3z"/>
      </g>
    </g>
    <g>
      <g>
        <polygon points="390.7,165.7 388.6,154 436.4,142.5 439.2,154.1"/>
      </g>
    </g>
    <g>
      <rect x="405" y="225.5" transform="matrix(0.9848 -0.1737 0.1737 0.9848 -33.5059 80.0455)" width="71.4" height="12"/>
    </g>
    <g>
      <g>
        <polygon points="376.9,87.7 374.8,76 444.8,53.8 448.4,65.3"/>
      </g>
    </g>
  </g>
  </svg>

</template>

<style>
  .st0{fill:#CE161D;}
</style>
