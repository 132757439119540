<template>
  <div class="absolute map-holder w-full h-full z-50"> 
    <div v-if="overlay" @click="overlay = false" class="w-full h-full absolute z-60">
      <svg fill="#000" class="w-6 h-6 transform absolute top-16 right-28 z-50" viewBox="0 0 30.92 30.92"><path d="M8.29 0v4h15.8L0 28.09l2.83 2.83L26.92 6.83v15.8h4V0H8.29z"/></svg>
      <p class="p-locatie p-4 bg-white absolute right-8 top-20 z-40 w-48 text-sm shadow-2xl">Klik op 'mijn locatie' om je locatie te bekijken. Automatisch wordt je nieuwe locatie getoond.</p>
      <svg fill="#000" class="w-6 h-6 transform -rotate-90 absolute z-50 svg-marker" viewBox="0 0 30.92 30.92"><path d="M8.29 0v4h15.8L0 28.09l2.83 2.83L26.92 6.83v15.8h4V0H8.29z"/></svg>    
      <p class="p-marker p-4 bg-white absolute z-40 w-48 text-sm shadow-2xl">Klik op een marker om de het audiofragment af te spelen.</p>      
    </div>   
    <div class="map-corner map-corner-tl"></div>
    <div class="map-corner map-corner-tr"></div>
    <div class="map-corner map-corner-br"></div>
    <div class="map-corner map-corner-bl"></div>
    <div id="map" class="w-full h-full rounded-xl absolute" ref="map"></div>
    <a @click="buttonLive === 'mijn locatie' ? getUserLocation() : stopUserLocation()" class="uppercase border-2 border-black bg-black absolute top-4 right-2 text-white hover:text-black hover:bg-white text-base font-mix-bold cursor-pointer py-1 px-3">
      {{ buttonLive }}
    </a>
    <a @click="close" class="uppercase border-2 border-black bg-white absolute top-4 left-2 text-black hover:text-white hover:bg-black text-base font-mix-bold cursor-pointer py-1 px-3">
      audioplayer
    </a>
    <div class="uppercase absolute pl-10 pr-3 py-2 bg-white bottom-8 left-8 text-sm font-mix-bold">
       {{ currentSong + 1 }}. {{ currentName }}
     </div>    
    <button 
      v-on:click="playAudio()" 
      title="Play/Pause Song" 
      type="button" 
      class="h-14 w-14 absolute bottom-6 left-2 rounded-full bg-black mx-auto flex flex-wrap justify-center content-center transition duration-300 ease-in-out transform hover:scale-95 focus:outline-none"
    >          
      <svg 
        v-if="!playing" 
        fill="#fff" 
        class="w-8 h-8" 
        viewBox="0 0 50 50"
      >
        <path d="M9.46 43.82V6.18L42 25zm3-32.44v27.24L36 25z" data-name="Layer 1"/>
      </svg>
      <svg
        v-else 
        fill="#ffffff" 
        class="w-8 h-8"
        viewBox="0 0 50 50"
      >
        <path d="M38 43.19H28V6.81h10zm-7.08-3H35V9.81h-4zM22 43.19H12V6.81h10zm-7.08-3H19V9.81h-4z"/>
      </svg>
    </button> 
  </div>
</template>

<script>

import {Loader} from 'google-maps'
import { mapState, mapGetters } from 'vuex'
import mapStyle from '/static/data/mapstyle.json'

// open een infowindow - fixed
// infowindow styling 
// importeer tailwind css
// audio player toevoegen aan de app

// maak er een app van


export default {
  data() {
    return {
      buttonLive: 'mijn locatie',
      overlay: true,
      locationInterval: null,
      map: null,
      google: null,
      mapCenter: { lat: 52.07879221676439, lng: 4.312987569751817 },
      wayPointsStart: [
        { lat: 52.076728359326886, lng: 4.316850487942877, stopover: true },
        { lat: 52.080424457541554, lng: 4.310872116220734, stopover: true },
        { lat: 52.082993122026785, lng: 4.313835837762958, stopover: true },
        { lat: 52.08192750311039, lng: 4.310358353849039, stopover: true },
        { lat: 52.08123191137093, lng: 4.307000228270996, stopover: true },         
      ],
      wayPointsVredespaleis: [
        { lat: 52.08123191137093, lng: 4.307000228270996, stopover: true },
        { lat: 52.08721981545749, lng: 4.298572619245807, stopover: true },
      ],
      wayPointsNext: [
        { lat: 52.08721981545749, lng: 4.298572619245807, stopover: true },
        { lat: 52.08739802452346, lng: 4.2978185681557655, stopover: true },
        { lat: 52.09298753803996, lng: 4.288936205233803, stopover: true },
        { lat: 52.08921039063494, lng: 4.283400126010221, stopover: true },
        { lat: 52.08475002814666, lng: 4.294305911607615, stopover: true },
        { lat: 52.08475002814666, lng: 4.294305911607615, stopover: true },        
        { lat: 52.08526693820424, lng: 4.299150878939633, stopover: true },
        { lat: 52.08105397733116, lng: 4.303002530935168, stopover: true },        
        { lat: 52.0769247011935, lng: 4.295519496882844, stopover: true },
        { lat: 52.07656203114367, lng: 4.295079614635431, stopover: true },
        { lat: 52.07500251632179, lng: 4.300240184553416, stopover: true },
        { lat: 52.07415337189832, lng: 4.301895828057972, stopover: true },
      ], 
      wayPointsPrinsegracht: [
        { lat: 52.07415337189832, lng: 4.301895828057972, stopover: true },
        { lat: 52.07300758305885, lng: 4.302263290686016, stopover: true },
      ], 
      wayPointsEnd: [
        { lat: 52.07300758305885, lng: 4.302263290686016, stopover: true },
        { lat: 52.07502722895312, lng: 4.308054920860025, stopover: true },
        { lat: 52.07534375204211, lng: 4.309728619167254, stopover: true },
        { lat: 52.07568885441847, lng: 4.312725558879782, stopover: true },
        { lat: 52.07416174152804, lng: 4.317359150136863, stopover: true },
        { lat: 52.07530601310924, lng: 4.319603794945052, stopover: true },
        { lat: 52.076728359326886, lng: 4.316850487942877, stopover: true },
      ],
      markers: [

      ],
      directionsDisplay: [],
      directionsService: [],
      route: null,
      livePos: null,
      mapInfoWindow: null
    }
  },
  computed: {
    ...mapState(['playing', 'currentSong']),
    ...mapGetters(['currentName'])
  },
  async mounted() {
    try {
      const loader = new Loader('AIzaSyChdZjJeM6wd5T7HtG941nNkaLSuLPLB98', {});
      this.google = await loader.load();
      this.map = new this.google.maps.Map( this.$refs.map, {
        center: this.mapCenter,
        zoom: 18,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        styles: mapStyle
      })
      // this.initMarkers()
      // this.directionsService[0] = new this.google.maps.DirectionsService()
      // this.directionsDisplay[0] = new this.google.maps.DirectionsRenderer({ preserveViewport: true, suppressMarkers: true, polylineOptions: { strokeColor: "#f8d62d" } })
      // this.directionsDisplay[0].setMap(this.map);
      
      this.calcRoute(0, this.wayPointsStart, this.google.maps.DirectionsTravelMode.BICYCLING)
      this.calcRoute(1, this.wayPointsVredespaleis, this.google.maps.DirectionsTravelMode.WALKING)
      this.calcRoute(2, this.wayPointsNext, this.google.maps.DirectionsTravelMode.BICYCLING)
      this.calcRoute(3, this.wayPointsPrinsegracht, this.google.maps.DirectionsTravelMode.WALKING)
      this.calcRoute(4, this.wayPointsEnd, this.google.maps.DirectionsTravelMode.BICYCLING)
      this.initMarkers()
      this.setLine()

      this.mapInfoWindow = new this.google.maps.InfoWindow({
        content: '',
        maxWidth: 200
      })

    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    close() {
      this.$emit('closeMap')
    },
    calcRoute( number, points, mode ) {

      this.directionsService[number] = new this.google.maps.DirectionsService()
      this.directionsDisplay[number] = new this.google.maps.DirectionsRenderer({ preserveViewport: true, suppressMarkers: true, polylineOptions: { strokeColor: "#F08393" } })
      this.directionsDisplay[number].setMap(this.map);

      let waypts = [];
      points.forEach((point) => {
        waypts.push({
          location: new this.google.maps.LatLng(point.lat, point.lng),
          stopover: true
        })
      })

      const request = {
          origin: waypts[0].location,
          destination: waypts[waypts.length - 1].location,
          waypoints: waypts,
          optimizeWaypoints: false,
          travelMode: mode
      }

      const self = this
      this.directionsService[number].route(request, function (response, status) {
          if (status == self.google.maps.DirectionsStatus.OK) {
            // console.log(response)
            self.directionsDisplay[number].setDirections(response)
            self.route = response.routes[0]
          }
      });
    },
    initMarkers() {
      // this.setMarker(new this.google.maps.LatLng(52.09811504730472, 4.292836021519339), require('../assets/images/icon-marker-rest.svg'), 1)
      // this.setMarker(new this.google.maps.LatLng(52.09005828856401, 4.280686424017461), require('../assets/images/icon-marker-visit.svg'), 1) // kunstmuseum
      // this.setMarker(new this.google.maps.LatLng(52.08704431692635, 4.2955389925256275), require('../assets/images/icon-marker-visit.svg'), 1) // vredespaleis
      // this.setMarker(new this.google.maps.LatLng(52.086886097144465, 4.296686977974954), require('../assets/images/icon-marker-rest.svg'), 1) // vredespaleis
      // this.setMarker(new this.google.maps.LatLng(52.08654328569259, 4.295775026917078), require('../assets/images/icon-marker-insta.svg'), 1) // vredespaleis
      // this.setMarker(new this.google.maps.LatLng(52.080381592840126, 4.314239141229658), require('../assets/images/icon-marker-visit.svg'), 1) // mauritshuis
      // this.setMarker(new this.google.maps.LatLng(52.07960996595313, 4.313488650571559), require('../assets/images/icon-marker-insta.svg'), 1) // binnenhof

      this.setInfoMarker(new this.google.maps.LatLng(52.08009484678597, 4.313478431116711), require('../assets/images/icon-marker-info.svg'), 2, 'Het Binnenhof kan wegens verbouwingen tijdelijk gesloten zijn.')

      this.setAudioMarker(new this.google.maps.LatLng(52.07879221676439, 4.312987569751817), require('../assets/images/icon-marker-1.svg'), 100, 1)
      this.setAudioMarker(new this.google.maps.LatLng(52.08054430395315, 4.312956333702683), require('../assets/images/icon-marker-2.svg'), 200, 2)
      this.setAudioMarker(new this.google.maps.LatLng(52.08114532776203, 4.306704561799137), require('../assets/images/icon-marker-3.svg'), 300, 3)
      this.setAudioMarker(new this.google.maps.LatLng(52.0870735294275, 4.295508013928549), require('../assets/images/icon-marker-4.svg'), 400, 4)
      this.setAudioMarker(new this.google.maps.LatLng(52.089408438232084, 4.282636856258024), require('../assets/images/icon-marker-5.svg'), 500, 5)
      this.setAudioMarker(new this.google.maps.LatLng(52.07413267628719, 4.300973346509195), require('../assets/images/icon-marker-6.svg'), 600, 6)
      this.setAudioMarker(new this.google.maps.LatLng(52.07533534211893, 4.313664240822568), require('../assets/images/icon-marker-7.svg'), 700, 7)
      this.setAudioMarker(new this.google.maps.LatLng(52.0789826659531, 4.314547083245903), require('../assets/images/icon-marker-8.svg'), 800, 8)
    },
    setLine() {
      const pathCoordinates = [
        { lat: 52.08193324238481, lng: 4.3229618261629295 },
        { lat: 52.08221313142099, lng: 4.322627 }
      ]
      const path = new this.google.maps.Polyline({
        path: pathCoordinates,
        geodesic: true,
        strokeColor: "#f8d62d",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      })
      path.setMap(this.map)
    },
    setMarker(points, icon, z) {
      let markerAnchor = new this.google.maps.Point(20, 20);
      let markerSize = new this.google.maps.Size(40, 40);
      let marker = new this.google.maps.Marker({
        position: points,
        map: this.map,
        icon: {
          anchor: markerAnchor,
          scaledSize: markerSize,
          url: icon,          
        },
        zIndex: z
      })
      return marker;
    },
    setInfoMarker(points, icon, z, content) {
      let marker = this.setMarker(points, icon, z)
      marker.addListener("click", () => {
        // this.$gtag.event('googlemaps', {
        //   'event_category' : 'maps',
        //   'event_label' : 'click info marker'
        // })
        this.mapInfoWindow.setContent(content)
        this.mapInfoWindow.open(this.map, marker)
      })
    },
    setAudioMarker(points, icon, z, content) {
      let marker = this.setMarker(points, icon, z)
      marker.addListener("click", () => {
        // this.$gtag.event('googlemaps', {
        //   'event_category' : 'maps',
        //   'event_label' : 'click audio marker '+(content - 1)
        // })
        this.$store.commit('setCurrentSong', content - 1)
        this.$emit('changeNumber')
        this.$emit('play')
      })
    },
    stopUserLocation() {      
      clearInterval(this.locationInterval)
      this.livePos.setMap(null)
      this.livePos.setIcon(require('../assets/images/icon-marker-trans.svg'))
      this.buttonLive = 'mijn locatie'
      this.locationInterval = null
    },
    getUserLocation() {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {        
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const point = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            if (this.livePos === null) {
              // this.$gtag.event('googlemaps', {
              //   'event_category' : 'maps',
              //   'event_label' : 'live location'
              // })
              this.buttonLive = 'stop mijn locatie'
              const markerAnchor = new this.google.maps.Point(10, 10);
              const markerSize = new this.google.maps.Size(20, 20);
              this.livePos = new this.google.maps.Marker({
                position: point,
                map: this.map,
                icon: {
                  anchor: markerAnchor,
                  scaledSize: markerSize,
                  url: require('../assets/images/icon-marker-live.svg')
                }
              })
              this.locationInterval = setInterval(() => { this.getUserLocation() }, 5000)
              this.map.setCenter(point)
            }
            else {
              if (this.locationInterval === null) {
                this.livePos.setIcon(require('../assets/images/icon-marker-live.svg'))
                this.locationInterval = setInterval(() => { this.getUserLocation() }, 5000)
              }
              this.buttonLive = 'stop mijn locatie'
              this.livePos.setPosition(point)
              this.map.setCenter(point)
            }
          },
          () => {
            console.log("Error: The Geolocation service failed.")
          }
        );
      } else {
        console.log("Error: Your browser doesn't support geolocation.")
      }
    },
    playAudio() {
      this.$emit('play')
    }
  }
}

</script>

<style scoped lang="scss">

.p-marker {
  left: 40%;
  top: 50%;
  margin-top: 42px;
}

.svg-marker {
  left: 40%;
  top: 50%;
  margin-top: 28px;
  margin-left: 60px;
}

.map-corner {
  width: 30px;
  height: 30px;
  position: absolute;
  overflow: hidden;
  z-index: 5;

  &:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    box-shadow: -20px 40px 0px 50px #f8d62b;
    border-radius: 0.75rem;
  }

  &-tr {
    right: 0;
    &:before {
      right: 0;
    }
  }

  &-bl {
    bottom: 0;
    &:before {
      bottom: 0;
    }
  }

  &-br {
    right: 0;
    bottom: 0;
    &:before {
      right: 0;
      bottom: 0;
    }
  }
}

.z-60 {
  z-index: 60;
}
</style>
