<template>
  <div class="container h-full w-full flex flex-wrap justify-center content-center p-2 mx-auto">
    <div class="max-w-sm w-full flex flex-col bg-white text-black rounded-xl h-full max-h-192 relative overflow-hidden justify-between border">
      <!-- Google Maps -->
      <google-maps v-show="isMapActive" @closeMap="toggleMap" @changeNumber="changeSong" @play="playAudio" />
      <!-- Playlist -->
      <div v-show="isPlaylistActive" class="bg-black absolute top-0 left-0 w-full h-full z-40"> 
        <playlist @closeList="togglePlaylist" @changeNumber="changeSong" @play="playAudio" :data="playlist" />
      </div>
      <!-- Audioplayer -->
      <div v-show="!isMapActive" class="audioplayer px-8 h-full flex flex-col justify-between">
        <div class="h-full flex flex-wrap">
          <div class="w-full">
            <div class="flex align-center justify-center text-center">
              <div class="relative z-20">
                <image-top class="image-top absolute left-0" />
                <img @load="onImageLoaded()" :src="playlist[currentSong].image" :key="currentSong" ondragstart="return false;" alt="" width="140" height="140" class="item-image w-56 h-56 bg-gray-100 border-solid border-3 border-black" />
              </div>
            </div>
            <div class="h-1 w-full color-pink mt-12 mb-8 relative">
              <div class="absolute h-12 w-12 bg-black flex flex-wrap justify-center font-mix-bold content-center top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <span class="text-2xl number text-white">{{ playlist[currentSong].number }}</span>
              </div>
            </div>    
            <div class="content text-center">
              <transition name="slide-fade" mode="out-in">
                <div>
                  <h2 :key="currentSong" class="text-center text-3xl leading-8 font-mix-bold uppercase">{{ playlist[currentSong].title }}</h2>
                  <p :key="currentSong" class="text-center text-lg tall:text-xl mt-2 leading-6 font-medium">{{ playlist[currentSong].subtitle }}</p>
                </div>
              </transition>
              <transition name="slide-fade" mode="out-in">
                <h4 :key="currentSong" class="text-md">{{ playlist[currentSong].artist }}</h4>
              </transition>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="text-black lg:rounded-b-xl pt-4 px-1 sm:px-3 lg:px-1 xl:px-3 grid grid-cols-3 items-center">
              <button 
                :class="{'pointer-events-none':(currentSong==0)}"
                v-on:click="prevSong()" title="Previous Song" 
                type="button" 
                class="h-12 w-12 rounded-full bg-gray-200 mx-auto flex flex-wrap justify-center content-center transition duration-300 ease-in-out transform hover:scale-95 focus:outline-none"
              >
                <svg :class="{'opacity-25':(currentSong==0)}" class="w-8 h-8" viewBox="0 0 50 50">
                  <path d="M38.31 44.59L15.43 25.15 38.31 5.08zM20 25.11l15.29 13v-26.4z" />
                  <path d="M11.69 6.64h3v36.47h-3z"/>
                </svg>
              </button>
              <button 
                v-on:click="playAudio()" 
                title="Play/Pause Song" 
                type="button" 
                class="h-20 w-20 rounded-full bg-black mx-auto flex flex-wrap justify-center content-center transition duration-300 ease-in-out transform hover:scale-95 focus:outline-none"
              >          
                <svg 
                  v-if="!playing" 
                  fill="#fff" 
                  class="w-12 h-12" 
                  viewBox="0 0 50 50"
                >
                  <path d="M9.46 43.82V6.18L42 25zm3-32.44v27.24L36 25z" data-name="Layer 1"/>
                </svg>
                <svg
                  v-else 
                  fill="#ffffff" 
                  class="w-12 h-12"
                  viewBox="0 0 50 50"
                >
                  <path d="M38 43.19H28V6.81h10zm-7.08-3H35V9.81h-4zM22 43.19H12V6.81h10zm-7.08-3H19V9.81h-4z"/>
                </svg>
              </button>
              <button 
                :class="{'pointer-events-none':(currentSong==playlist.length-1)}"
                v-on:click="nextSong()" 
                title="Next Song" 
                type="button" 
                class="h-12 w-12 rounded-full bg-gray-200 mx-auto flex flex-wrap justify-center content-center transition duration-300 ease-in-out transform hover:scale-95 focus:outline-none"
              >
                <svg :class="{'opacity-25':(currentSong==playlist.length-1)}" class="w-8 h-8" viewBox="0 0 50 50">
                  <path d="M11.69 44.79V5.28l22.88 19.44zm3-33v26.4L30 24.76z" />
                  <path d="M35.31 6.77h3v36.47h-3z"/>
                </svg>
              </button>
            </div>

            <div class="currentTimeContainer" style="text-align:center">
              <span class="currentTime text-xs">{{ fancyTimeFormat(currentTime) }}</span>
              <span class="totalTime text-xs"> {{ fancyTimeFormat(trackDuration) }}</span>
            </div>

            <div class="currentProgressBar">
              <div class="currentProgress" :style="{ width: currentProgressBar + '%' }"></div>
            </div>
          </div>
        </div>
        <div class="nav-bottom grid grid-cols-2">
          <div class="text-center">
            <a @click="toggleMap" class="underline hover:no-underline text-base font-mix-bold uppercase">kaart</a>
          </div>          
          <div class="text-center">
            <a @click="togglePlaylist" class="underline hover:no-underline text-base font-mix-bold uppercase">playlist</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Playlist from '@/components/Playlist'
  import GoogleMaps from '@/components/GoogleMaps'
  import ImageTop from '@/components/ImageTop'
 
  export default {
    components: {
      Playlist,
      GoogleMaps,
      ImageTop
    },
    data () {
      return {
        audio: null,
        imgLoaded: false,
        currentlyStopped: false,
        currentTime: 0,
        checkingCurrentPositionInTrack: "",
        trackDuration: 0,
        currentProgressBar: 0,
        isPlaylistActive: false,
        audioFile: "",
        isMapActive: true,
      }
    },
    computed: {
      ...mapState(['playlist', 'playing', 'currentSong'])
    },    
    mounted () {
      // this.$gtag.pageview({
      //   page_path: '/home',
      // })
      
      this.changeSong()
      this.audio.loop = false;
    },    
    methods: {
      fancyTimeFormat: function (s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + Math.round(s)
      },
      togglePlaylist: function () {
        this.isPlaylistActive = !this.isPlaylistActive;
      },
      toggleMap: function () {
        this.isMapActive = !this.isMapActive;
      },      
      nextSong: function () {
        if (this.currentSong < this.playlist.length - 1) {
          this.$store.commit('setCurrentSong', this.currentSong + 1)
          this.changeSong()
        }
      },
      prevSong: function () {
        if (this.currentSong > 0) {
          this.$store.commit('setCurrentSong', this.currentSong - 1)
          this.changeSong()
        }
      },
      changeSong: function () {
        if(this.audio) { 
          this.stopAudio()
          this.currentTime = 0
          this.currentProgressBar = 0
        }
        this.imageLoaded = false;
        this.audioFile = this.playlist[this.currentSong].url;
        this.audio = new Audio(this.audioFile);
        var localThis = this;
        this.audio.addEventListener("loadedmetadata", function () {
          localThis.trackDuration = Math.round(this.duration);
        });
        this.audio.addEventListener("ended", this.handleEnded);

        // this.$gtag.event('player', {
        //   'event_category' : 'change',
        //   'event_label' : this.playlist[this.currentSong].title
        // })
      },
      isCurrentSong: function (index) {
        if (this.currentSong == index) {
          return true;
        }
        return false;
      },
      getCurrentSong: function (currentSong) {
        return this.playlist[currentSong].url;
      },
      playAudio: function () {
        if (
          this.currentlyStopped == true &&
          this.currentSong + 1 == this.playlist.length
        ) {
          this.$store.commit('setCurrentSong', 0)
          this.changeSong();
        }
        if (!this.playing) {
          // this.$gtag.event('player', {
          //   'event_category' : 'play',
          //   'event_label' : this.playlist[this.currentSong].title
          // })
          this.getCurrentTimeEverySecond(true);
          this.$store.commit('setPlaying', true)
          this.audio.play();
        } else {
          this.stopAudio();
        }
        this.currentlyStopped = false;
      },
      stopAudio: function () {
        this.audio.pause();
        this.$store.commit('setPlaying', false)
        this.pausedMusic();
      },
      handleEnded: function () {
        if (this.currentSong + 1 == this.playlist.length) {
          this.stopAudio();
          this.$store.commit('setPlaying', false)
          this.currentlyStopped = true;
        } else {
          this.$store.commit('setPlaying', false)
          this.$store.commit('setCurrentSong', this.currentSong + 1)
          this.changeSong();
          // this.playAudio();
        }
      },
      onImageLoaded: function () {
        this.imgLoaded = true;
      },
      getCurrentTimeEverySecond: function () {
        // startStop
        var localThis = this;
        this.checkingCurrentPositionInTrack = setTimeout(
        function () {
          localThis.currentTime = localThis.audio.currentTime;
          localThis.currentProgressBar =
          localThis.audio.currentTime / localThis.trackDuration * 100;
          localThis.getCurrentTimeEverySecond(true);
        }.bind(this),
        1000);
      },
      pausedMusic: function () {
        clearTimeout(this.checkingCurrentPositionInTrack);
      }
    }
  }
</script>

<style lang="scss" scoped>

.color-pink {
  // background-color: #edbf06;
  background-color: #F08393;
}
.number {
  transform: translateY(-3px);
}


$primary_color: rgba(0, 0, 0, 0.75);
$anim_duration: 0.5s;

.currentTimeContainer {
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: space-between;
}

.currentProgressBar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin:0.75rem 0;
  .currentProgress {
    background-color: $primary_color;
    width: 0px;
    height: 1px;
    transition: 100ms;
  }
}

/* data change transitions */
.slide-fade-enter-active {
	transition: all 0.3s ease;
}
.slide-fade-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
	transform: translateY(10px);
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity $anim_duration;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

@media screen and (max-height: 680px) {
  .audioplayer {
    @apply py-4;
  }
  .nav-bottom {
    @apply pt-2;
  }
}
@media screen and (min-height: 680px) {
  .audioplayer {
    @apply py-8;
  }
  .nav-bottom {
    @apply pt-4;
  }  
}

.image-top {
  z-index: -1;
  top: -26px;
  width: 95%;
}

</style>
