<template>
  <div class="container h-full w-full flex flex-wrap justify-center content-center p-2 mx-auto">
    <div class="content-container max-w-sm w-full bg-white text-black rounded-xl p-8 h-full max-h-192 relative overflow-hidden text-center border" :style="{ backgroundImage: `url(${require('@/assets/images/bg-home.svg')})` }">
      <a href="https://denhaagmeldt.nl" target="_blank">
        <logo class="absolute left-6 top-6 w-28" />
      </a>
      <div class="flex flex-col justify-center h-full">
        <intro-logo class="w-10/12 md:w-full mx-auto" />
        <div class="mt-6">
          <router-link to="/player" class="bg-black border-4 border-black text-white hover:bg-white hover:text-black text-2xl font-mix-bold cursor-pointer py-2 px-5 uppercase">
            start
          </router-link>
        </div>
      </div>
      <bottom-line-pattern class="absolute bottom-0 left-0" />  
    </div>
  </div>
</template>

<script>

  import BottomLinePattern from '@/components/BottomLinePattern'
  import Logo from '@/components/Logo'
  import IntroLogo from '@/components/IntroLogo'

  export default {
    components: {
      BottomLinePattern,
      Logo,
      IntroLogo
    },
    data () {
      return {
        show: true
      }
    },
    mounted () {
      // this.$gtag.pageview({
      //   page_path: '/home',
      // })
    }
  }
</script>

<style scoped>

.content-container {
  background-repeat: no-repeat;
  background-position: bottom center;
}

</style>