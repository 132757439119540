<template>
<svg width="414px" height="54px" viewBox="0 0 414 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="bg-home" fill-rule="nonzero">
            <polygon id="Path_866" fill="#0ADBED" points="24.3060705 15.2556872 414 10 414 53.6622399 0 53.6622399"></polygon>
            <polygon id="Path_867" fill="#FCEB20" points="0 0 224.934894 54.002203 0 54.002203"></polygon>
        </g>
    </g>
</svg>
</template>