<template>
  <li 
    @click="selectNumber(number)" 
    class="p-4 my-1 cursor-pointer flex space-x-4 hover:text-black hover:bg-white items-center"
    :class="{'bg-white text-black' : currentSong === number, 'bg-black text-white' : currentSong !== number }"
  >
    <img :src="content.image" alt="" class="w-12 h-12 object-cover" width="144" height="144" />
    <div class="min-w-0 relative flex flex-wrap pr-4">
      <h2 class="text-lg leading-normal font-mix-bold uppercase">
        {{ content.number }}. {{ content.title }}
      </h2>
    </div>
  </li>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      content: { 
        type: Object, 
        required: false, 
        default: function () { return {} }
      },
      number: Number
    },
    computed: {
      ...mapState(['currentSong'])
    },
    methods: {
      selectNumber(number) {
        this.$store.commit('setCurrentSong', number)
        this.$emit('changeSong')        
      }
    }
  }
</script>

<style scoped>

</style>
