<template>
  <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 210 130" style="enable-background:new 0 0 210 130;" xml:space="preserve">
  <g>
    <g>
      <polygon class="st0" points="5,50.2 5,103.3 63.1,103.3 68,106.4 73,103.3 204.8,103.3 204.8,40.6 		"/>
      <polygon class="st1" points="5,97.8 5,99.5 5,102.6 5,103.3 63.1,103.3 68,106.4 73,103.3 204.8,103.3 204.8,102.6 204.8,99.5 
        204.8,97.8 		"/>
    </g>
    <g>
      <g>
        <path class="st2" d="M49.1,64.1l-6.1,25H31.2l-6.1-24.9l-0.1,25.5h-10l1.2-34.6h15.7l5.4,24.7l5.4-24.7h15.5l1.2,34.6H49.2
          L49.1,64.1z"/>
        <path class="st2" d="M65.1,89.7V55.1h27.1v8.5H75.8v4.5h13v8.5h-13v4.7h16.4v8.5H65.1z"/>
        <path class="st2" d="M97.7,89.7V55.1h10.9v25.7H122v8.9H97.7z"/>
        <path class="st2" d="M140.9,89.7h-13.6V55.1h14.4c12.6,0,19.5,6.8,19.5,17.1C161.2,82.8,154,89.7,140.9,89.7z M141,63.4h-2.7
          v17.9h3.3c5.4,0,8.7-2.7,8.7-8.9C150.2,66,147.4,63.4,141,63.4z"/>
        <path class="st2" d="M184.6,64v25.7h-10.9V64h-10.5v-8.9h31.9V64H184.6z"/>
      </g>
      <g>
        <path class="st0" d="M14.5,35.8c-0.1,0-9.5,0-9.5,0V9.4c0,0,8.2,0,10,0c9.3,0,14.6,5.2,14.6,13C29.6,30.5,24.2,35.8,14.5,35.8z
           M14.6,14.7h-2.8v15.7h3.1c4.9,0,7.9-2.5,7.9-7.8C22.8,17.2,20.1,14.7,14.6,14.7z"/>
        <path class="st0" d="M33.9,35.8V9.4h19.4v5.4H40.5v5.1h10.3v5.4H40.5v5.2h12.8v5.4H33.9z"/>
        <path class="st0" d="M76.3,9.4h6.3v26.7h-7.2L64.2,18.7v17.2h-6.3V9.4h7.3l11,17.2V9.4z"/>
        <path class="st0" d="M115.2,35.8V25.3h-10.7v10.5h-6.8V9.4h6.8v10.3h10.7V9.4h6.8v26.5H115.2z"/>
        <path class="st0" d="M151.9,35.8h-6.7l-2-5.6h-9.9l-1.9,5.6h-6.7l9.4-26.5h8.4L151.9,35.8z M138.4,14.6L134.9,25h6.9L138.4,14.6z
          "/>
        <path class="st0" d="M179.2,35.8h-6.7l-2-5.6h-9.9l-1.9,5.6h-6.7l9.4-26.5h8.4L179.2,35.8z M165.7,14.6L162.2,25h6.9L165.7,14.6z
          "/>
        <path class="st0" d="M194.5,36.5c-8.5,0-14.3-5-14.3-13.8c0-8.7,5.6-14,14.3-14c3.4,0,7.4,0.8,10,2.3l-2.1,5.6
          c-2.5-1.4-5.1-2.2-7.6-2.2c-4.8,0-7.8,3.2-7.8,8.1c0,5.3,2.7,8.4,7.7,8.4c1.5,0,3.1-0.3,4.2-0.8v-3.9h-6.5v-5.3H205V34
          C202.1,35.6,198.3,36.5,194.5,36.5z"/>
      </g>
      <g>
        <g>
          <path class="st0" d="M7.7,121C7,121,5,121,5,121v-9.2c0,0,2,0,2.8,0c3,0,4.9,1.8,4.9,4.5C12.6,119.2,10.9,121,7.7,121z M7.7,113
            H6.3v6.9h1.5c2.2,0,3.6-1.2,3.6-3.4C11.4,114.2,10,113,7.7,113z"/>
          <path class="st0" d="M23.6,121v-9.2h1.2v9.2H23.6z"/>
          <path class="st0" d="M40.7,118.6c0-0.9-0.7-1.3-2.2-1.7c-1.3-0.4-2.7-0.9-2.7-2.7c0-1.5,1.3-2.6,3.1-2.6c1.1,0,2,0.3,2.9,0.8
            l-0.4,1.1c-0.7-0.5-1.6-0.8-2.4-0.8c-1.3,0-1.9,0.6-1.9,1.4c0,0.9,0.8,1.2,2.2,1.6c1.3,0.4,2.7,1,2.7,2.7c0,1.6-1.3,2.7-3.2,2.7
            c-1.1,0-2.2-0.3-3.1-0.9l0.4-1.1c0.8,0.5,1.6,0.9,2.7,0.9C39.9,120.2,40.7,119.5,40.7,118.6z"/>
          <path class="st0" d="M59.4,113.5c-0.8-0.5-1.6-0.8-2.5-0.8c-2,0-3.3,1.4-3.3,3.7s1.4,3.7,3.4,3.7c1,0,2-0.4,2.6-0.9l0.2,1.2
            c-0.8,0.5-1.8,0.8-2.8,0.8c-2.7,0-4.6-1.9-4.6-4.8s1.9-4.8,4.6-4.8c1,0,2.2,0.3,2.9,0.8L59.4,113.5z"/>
          <path class="st0" d="M71.9,121h-1.3v-9.2c0,0,2.7,0,2.7,0c2.2,0,3.4,1.1,3.4,2.7c0,1.4-0.8,2.2-2.1,2.6l2.5,3.8h-1.5l-2.2-3.6
            l-1.6,0.1V121z M73.4,116.4c1.2,0,2.1-0.6,2.1-1.7c0-1.1-0.9-1.6-2.3-1.6h-1.4v3.4L73.4,116.4z"/>
          <path class="st0" d="M87.8,121v-9.2H89v9.2H87.8z"/>
          <path class="st0" d="M108.4,113.4l-2.8,7.5h-1.2l-2.8-7.5l-0.1,7.6h-1.2l0.3-9.2h1.7l2.8,7.5l2.8-7.5h1.6l0.3,9.2h-1.3
            L108.4,113.4z"/>
          <path class="st0" d="M120.9,121v-9.2h1.2v9.2H120.9z"/>
          <path class="st0" d="M139.8,111.9h1.2v9.3h-1.4l-4.9-7.5v7.4h-1.2v-9.2h1.4l4.9,7.4V111.9z"/>
          <path class="st0" d="M159.6,121h-1.3l-0.9-2.4h-4l-0.9,2.4h-1.3l3.5-9.2h1.4L159.6,121z M155.5,113.2l-1.6,4.3h3.2L155.5,113.2z
            "/>
          <path class="st0" d="M172.8,113.1v8h-1.2v-8h-3.2v-1.2h7.7v1.2H172.8z"/>
          <path class="st0" d="M186.5,121v-9.2h1.2v9.2H186.5z"/>
          <path class="st0" d="M199.2,121v-9.2h5.8v1.2h-4.6v2.8h3.8v1.2h-3.8v2.9h4.6v1.2H199.2z"/>
        </g>
      </g>
    </g>
  </g>
  </svg>

</template>

<style scoped>
  .st0{fill:#1D1D1B;}
  .st1{fill:#CE141A;}
  .st2{fill:#FFFFFF;}

</style>